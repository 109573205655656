import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";
import html2canvas from 'html2canvas';
import { pafekutoAPI } from './constant';


function Result() {
    const qrCodeRef = useRef(null);
    const { orderNumber } = useParams();
    console.log("# orderNumber: ", orderNumber);
    const urlGetInfo = "https://www.pafekutogroup.com/reserve/reserved/"+orderNumber;

    const handleDownload = () => {
        if (qrCodeRef.current) {
            html2canvas(qrCodeRef.current).then((canvas) => {
            // Convert canvas to image data URL
            const dataUrl = canvas.toDataURL('image/png');

            // Create a temporary link and trigger a download
            const a = document.createElement('a');
            a.href = dataUrl;
            a.download = `qrcode-pafekuto-${orderNumber}.png`;
            a.click();
            });
        }
    };

    const buttonStyle = {
        marginTop: 10,
        width: '240px',
        padding: 10,
        borderStyle: 'none',
        borderRadius: 5,
        backgroundColor: '#333333',
        fontSize: 18,
        fontWeight: 'bold',
        color: '#ffffff',
        cursor: 'pointer'
    };

    const textStyle = {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#ffffff',
        textAlign: 'center',
        marginBottom: 10
    };

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20
    }}>
            <div style={textStyle}>เลขการจอง: {orderNumber}</div>
            <div style={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                padding: 10
            }}
            ref={qrCodeRef}
            >
                <QRCode value={urlGetInfo} style={{borderStyle: 'solid', borderWidth: '1px', borderColor: '#ffffff' }} />
                <button style={buttonStyle} onClick={handleDownload} >Download QRCode</button>
                <button style={buttonStyle}
                    onClick={()=>{
                        document.location = "https://www.pafekutogroup.com/reserve/";
                    }}
                >กลับหน้าจอง</button>
            </div>
        </div>
    

}

export default Result;