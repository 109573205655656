import React, { useContext, useState } from 'react';
import './Calendar.css'
import Calendar from 'react-calendar';
import { MainContext } from './main-context';
import Header from './components/Header';
import Steps from './components/Steps';

function DateBooking() {
  //const [dateInfo, setDateInfo] = useState(new Date()); 

  const { primaryData, shop, screen, bookingDate, setScreen, branch, menuType, setBookingDate, reserveSteps, setReserveSteps } = useContext(MainContext);
  
  // If data not ready then return empty (avoid error)
  if((!primaryData)||[shop,branch,menuType].includes(null)) return<></>;
  
  // Destruct important data
  const { shops, menuTypes, main } = primaryData;
  const { use_last_booking_date, last_booking_date_value } = main;
  const minDate = new Date(main.currentTime);
  let maxDate = null;
  if(use_last_booking_date === 'yes'){
    maxDate = new Date(last_booking_date_value);
  }else{
    // can booking forward 30 days
    maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 30);
  }

  // const unavailable = [new Date(2023, 5, 8).getTime(), new Date(2023, 5, 13).getTime(), new Date(2023, 4, 21).getTime()];
  const unavailable = []; // for disable date

  // Create key name for menuTypes
  const keyMenu = shop+"___"+branch;
  const { prices, label: labelMenu } = menuTypes[keyMenu][menuType];
  const labelShopBranchMenu = shops[shop].branch[branch].label+" "+labelMenu;

  const convertDate = (dateIn) => {
    var mm = dateIn.getMonth() + 1; // getMonth() is zero-based
    var dd = dateIn.getDate();
  
    return [dateIn.getFullYear(),
            (mm>9 ? '' : '0') + mm,
            (dd>9 ? '' : '0') + dd
           ].join('-');
  }

  // Create Header info
  let shopName = null;
  let shopLogo = null;
  if((screen != "welcome")&&(screen != "shop")){
    shopName = labelShopBranchMenu;
    shopLogo = shops[shop].logo; 
  }
  const headerInfo = {
    title: shopName,
    logo: shopLogo
  };

  // Create handle function for event change
  const handleClick = (dateGet, event) => {
    const tms = dateGet.getTime();
    if(unavailable.includes(tms)){
      alert("เต็ม-ไม่สามารถจองได้");
      return;
    }
    setBookingDate(convertDate(dateGet));
    setScreen("tables");
  }
  
  return (
    (screen === "date-booking") && 
    <div className="Container">
      <Header info={headerInfo}/>
      <Steps/>
      {/* <div style={{ fontSize: 28, marginTop: -20 }} >Reserve</div> */}
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: -30
      }}>
        <div style={{ width: '90%' }} >
          <Calendar 
            onChange={handleClick}
            minDate={minDate}
            maxDate={maxDate}
            // value={[new Date(2023, 4, 5), new Date(2023, 4, 21)]}
            tileClassName={({ date }) => {
              const tms = date.getTime();
              if(unavailable.includes(tms)) return "react-calendar-unavailable";
              return null;
            }}
          />
        </div>
      </div>
      <div 
        style={{ 
          display: 'flex',
          justifyContent: 'center',
          marginTop: 20
        }}
      >
        <div style={{ backgroundColor: '#d10000', width: 20, height: 20 }} ></div>
        <div style={{ fontSize: 22, marginLeft: 12 }} >Fully Booking</div>
      </div>
    </div>
  );
}

export default DateBooking;
