import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { pafekutoAPI } from './constant';

function Reserved() {
    const { orderNumber } = useParams();
    console.log("# orderNumber: ", orderNumber);

    const [details, setDetails] = useState(null);

    useEffect(() => {
        axios.post(`${pafekutoAPI}/reseved-details/${orderNumber}`,{}).then((response)=>{
            const { data } = response;
            console.log(">>> reseved-details");
            console.log("# data: ", data);
            setDetails(data);
        });        
    },[]);

    if(!details) return <></>;

    const {tx_code, open_date, table_code, time_code, name, tel, email, total_price, reserve_price, source, amount, shop, branch, offer_type, package: pckg } = details;
    const dateRec = new Date(open_date);
    const dateFM = dateRec.getDate()+"/"+(dateRec.getMonth()+1)+"/"+dateRec.getFullYear();
    let paymentSource = "";
    if(source==="CC") paymentSource = "Credit Card";
    if(source==="PPQR") paymentSource = "QR Promtpay";
    const remain = parseInt(total_price)-parseInt(reserve_price);
    
    const tableRow = (label, value) => {
        return  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: '7px' }}>
                    <div style={{width: '45%', marginRight: '5%', textAlign: 'right' }} >{label}</div>
                    <div style={{width: '50%', textAlign: 'left'}} >{value}</div>
                </div>;
    }

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        color: '#ffffff',
        fontSize: '16px',
        marginLeft: '20px',
        marginRight: '20px',
        width: '300px'
    }}>
        <div style={{ width: '100%', textAlign: 'center', marginTop: '10px', marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }} >ข้อมูลการจองออนไลน์</div>
        {tableRow('หมายเลขจอง: ', tx_code)}
        {tableRow('วันที่: ', dateFM)}
        {tableRow('ร้าน: ', shop)}
        {tableRow('สาขา: ', branch)}
        {tableRow('ประเภท: ', offer_type)}
        {tableRow('แพ็คเกจ: ', pckg)}
        {tableRow('โต๊ะที่จอง: ', table_code)}
        {tableRow('เวลาที่จอง: ', time_code)}
        {tableRow('จำนวนคน: ', amount)}
        {tableRow('ชื่อผู้จอง: ', name)}
        {tableRow('เบอร์โทร: ', tel)}
        {tableRow('อีเมล: ', email)}
        {tableRow('วิธีชำระเงินจอง: ', paymentSource)}
        {tableRow('จำนวนเงิน: ', `${total_price} บาท`)}
        {tableRow('จ่ายแล้ว: ', `${reserve_price} บาท`)}
        {tableRow('คงเหลือ: ', `${remain} บาท`)}
    </div>
    

}

export default Reserved;