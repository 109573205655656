import React from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Reservation from './Reservation';
import Result from './Result';
import Fail from './Fail';
import Reserved from './Reserved';
import Login from './Login';
import Transactions from './admin/Transactions';

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Reservation />} />
          <Route path="/result/:orderNumber" element={<Result />} />
          <Route path="/fail/:orderNumber" element={<Fail />} />
          <Route path="/reserved/:orderNumber" element={<Reserved />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin/transactions" element={<Transactions />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
