import { useContext, useEffect } from 'react';
import { MainContext } from './main-context';
import Header from './components/Header';
import Selector from './components/Selector';
import { trackingSteps } from './constant';

function MenuTypes() {
  // Get global data from context
  const { primaryData, shop, screen, setScreen, branch, menuType, setMenuType, reserveSteps, setReserveSteps } = useContext(MainContext);
  
  useEffect(()=>{
    if(reserveSteps.length>0) setScreen(reserveSteps[0]);
  },[reserveSteps])

  // If data not ready then return empty (avoid error)
  if((!primaryData)||[shop,branch].includes(null)) return<></>;

  // Destruct important data
  const { shops, menuTypes } = primaryData;

  // Create key name for menuTypes
  const keyMenu = shop+"___"+branch;
  const labelShopBranch = shops[shop].branch[branch].label;

  // Create Header info
  let shopName = null;
  let shopLogo = null;
  if((screen != "welcome")&&(screen != "shop")){
    shopName = labelShopBranch;
    shopLogo = shops[shop].logo; 
  }
  const headerInfo = {
    title: shopName,
    logo: shopLogo
  };

  // Create root object for the first of selector
  const rootObj = { root: { 
    key: 'root',
    label: labelShopBranch
  }};
  
  // Create handle function for event change
  const handleSelect = async (value) => {
    if(value=="root"){
      setScreen("branch");
    }else{
      setMenuType(value);
      //setScreen("prices");
      let keyStepRef = 'default';
      if(trackingSteps[value])  keyStepRef = value;
      setReserveSteps(trackingSteps[keyStepRef]);
    }
  }

  const filteredDisActiveMenuTypes = {};
  for(let k in menuTypes[keyMenu]){
    if(menuTypes[keyMenu][k].status === 'active'){
      filteredDisActiveMenuTypes[k] = menuTypes[keyMenu][k];
    }
  }

  return (
    (screen == "menu-types") && 
    <div className="Container">
      <Header info={headerInfo}/>
      <Selector list={{...rootObj, ...filteredDisActiveMenuTypes}} handleSelect={handleSelect} />
    </div>
  );
}

export default MenuTypes;
