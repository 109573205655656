import React, { useContext, useEffect, useState } from 'react';
import { MainContext } from '../main-context';
import { initSwitchSteps } from '../constant';

function Steps(props) {
  const { 
    menuType, 
    reserveSteps, 
    screen, 
    setScreen, 
    swichOnOffSteps, 
    setSwichOnOffSteps,
    price, 
    setPrice,
    amount, 
    setAmount,
    bookingDate,
    setBookingDate
  } = useContext(MainContext);

  const runDisplaysActive = () => {
    console.log("# swichOnOffSteps before: ", swichOnOffSteps);
    let found = false;
    let tempSwitch = initSwitchSteps;
    for(const step of reserveSteps){
      tempSwitch[step] = !found;
      if(step===screen) found = true;
    }

    setTimeout(()=>{
      setSwichOnOffSteps({...swichOnOffSteps, ...tempSwitch});
    }, 200);
    
  }

  useEffect(()=>{
    runDisplaysActive();
    // alert(screen);
  },[screen]);

  /* useEffect(()=>{
    console.log(">>> Inside useEffect swichOnOffSteps");
    console.log("# swichOnOffSteps: ", swichOnOffSteps);
  },[swichOnOffSteps]); */

  const icon1 = require("../asset/images/paper.png");
  const icon2 = require("../asset/images/customer.png");
  const icon3 = require("../asset/images/timetable.png");
  const circle = require("../asset/images/circle-step.png");

  const styleIconBg = {
    backgroundColor: '#ffffff',
    borderStyle: 'none',
    borderRadius: '50%',
    paddingTop: 10,
    paddingBottom: 6,
    paddingLeft: 13,
    paddingRight: 13,
    marginBottom: 5
  }

  const renderComponentSteps = () => {
    const retComp = [];
    for(const step of reserveSteps){
      if(step==="prices"){
        retComp.push(<div style={{
            marginLeft: 0,
            marginRight: 20,
            cursor: 'pointer'
          }} 
          onClick={()=>{ 
            setPrice(null);
            setAmount(null);
            setBookingDate(null);           
            setScreen("prices"); 
          }} 
        >
          <div style={styleIconBg} ><img src={icon1} style={{ width: 24 }} /></div>
          <div>แพ็คเกจ</div>
          <div>{(swichOnOffSteps['prices'])&&<img src={circle} style={{ width: 18 }} />}</div>
        </div>);
      }else if(step==="amount"){
        retComp.push(<div style={{
            marginLeft: 20,
            marginRight: 20,
            cursor: 'pointer'
          }} 
          onClick={()=>{ 
            if(!price) return;

            setAmount(null);
            setBookingDate(null);   
            setScreen("amount"); 
          }} 
        >
          <div style={styleIconBg} ><img src={icon2} style={{ width: 24 }} /></div>
          <div>จำนวนคน</div>
          <div>{(swichOnOffSteps['amount'])&&<img src={circle} style={{ width: 18 }} />}</div>
        </div>);
      }else if(step==="date-booking"){
        retComp.push( <div style={{
            marginLeft: 20,
            marginRight: 0,
            cursor: 'pointer'
          }}
          onClick={()=>{ 
            if(!price || !amount) return;
            setBookingDate(null); 
            setScreen("date-booking"); 
          }} 
        >
          <div style={styleIconBg} ><img src={icon3} style={{ width: 24 }} /></div>
          <div>วันที่ / เวลา</div>
          <div>{(swichOnOffSteps['date-booking'])&&<img src={circle} style={{ width: 18 }} />}</div>
        </div>);
      }
    }
    return retComp;
  }


  return (
    <div className="Container">
      <div style={{ 
        width: '95%',
        marginRight: '5%', 
        display:'flex', 
        flexDirection: 'row', 
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
        marginTop: -20
      }}>
        <div style={{ 
          display:'flex', 
          flexDirection: 'row', 
          justifyContent: 'center',
          alignItems: 'baseline',
          background: 'linear-gradient(to bottom, black calc(83% - 1px), white calc(83% - 1px) calc(83% + 1px), black calc(17% + 1px))'
        }}>
          {renderComponentSteps()}
        </div>
      </div>
    </div>
  );
}

export default Steps;
