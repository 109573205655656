import { useContext } from 'react';
import { MainContext } from './main-context';
import Header from './components/Header';
import Steps from './components/Steps';
import { contactStaffPopUp } from './constant';

function Amount() {
  // Get global data from context
  const { primaryData, shop, screen, setScreen, branch, menuType, setAmount, mainPopUp, setMainPopUp, reserveStep, setReserveStep } = useContext(MainContext);
  
  // If data not ready then return empty (avoid error)
  if((!primaryData)||[shop,branch,menuType].includes(null)) return<></>;
  
  // Destruct important data
  const { shops, menuTypes } = primaryData;

  // Create key name for menuTypes
  const keyMenu = shop+"___"+branch;
  const { prices, label: labelMenu } = menuTypes[keyMenu][menuType];
  const labelShopBranchMenu = shops[shop].branch[branch].label+" "+labelMenu;

  // Create Header info
  let shopName = null;
  let shopLogo = null;
  if((screen != "welcome")&&(screen != "shop")){
    shopName = labelShopBranchMenu;
    shopLogo = shops[shop].logo; 
  }
  const headerInfo = {
    title: shopName,
    logo: shopLogo
  };

  // Create handle function for event change
  const handleClick = (value) => {
    if(value==='more'){
      const { many } = contactStaffPopUp;
      const { title, detail, width, height } = many;
      setMainPopUp({
        ...mainPopUp, 
        display: true,
        title,
        detail,
        width,
        height
      });
      return;
    }
    setAmount(value);
    setScreen("date-booking");
  }

  const rowCountStyle = {
    display: 'flex',
    flexAlign: 'center',
    justifyContent: 'center'
  };
  const countItem = {
    cursor: 'pointer',
    padding: 20
  }

  const row_1 = [];
  for(let x=2;x<=6;x++){
    row_1.push(<div style={countItem} onClick={()=>{ handleClick(x) }} >{x}</div>);
  }
  /*
  const row_2 = [];
  for(let x=7;x<=12;x++){
    row_2.push(<div style={countItem} onClick={()=>{ handleClick(x) }} >{x}</div>);
  }
  */
  return (
    (screen === "amount") && 
    <div className="Container">
      <Header info={headerInfo}/>
      <Steps step={2} />
      <div style={{marginTop: -30}} >
        <div style={rowCountStyle} >{row_1}</div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }} >
          <div style={{
            width: 120,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor: '#ffffff',
            color: '#000000',
            padding: 10,
            borderStyle: 'none',
            borderRadius: 20,
            cursor: 'pointer'
          }}
          onClick={()=>{ handleClick('more') }}
        >มากกว่า 6 คน</div>
        </div>
      </div>
    </div>
  );
}

export default Amount;
