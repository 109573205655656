import React, { useState, useEffect, useRef } from "react";
import { login } from './common/api';

function Login() {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const toLogin = async () => {
    const record = await login(username, password);
    if(!record){
      alert("Incorrect Username or Password");
      return;
    }
    console.log(document.location);
    const { origin } = document.location;
    document.location.href = `${origin}/reserve/admin/transactions`;
  }

  return <div className="Container-Login">
    <div className="title" >Staff Login</div>
    <div className="row-input" >
      <div className="label" >Username: </div>
      <div className="value" ><input type='text' onChange={(event)=>{ 
        setUsername(event.target.value);
      }}/></div>
    </div>
    <div className="row-input" >
      <div className="label" >Password: </div>
      <div className="value" ><input type='password' onChange={(event)=>{ 
        setPassword(event.target.value);
      }}/></div>
    </div>
    <div className="row-buttons" >
      <button onClick={()=>{ toLogin() }}>Login</button>
    </div>
  </div>
}
export default Login;