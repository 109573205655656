
import magnifier from '../asset/images/magnifier.png'
import close from '../asset/images/close.png'

function Selector(props) {
  const selectorFrame = {
    width: '86%',
    marginRight: '7%',
    marginLeft: '7%',
    height: 360,
    backgroundColor: '#ffffff',
    fontSize: 28,
    borderStyle: 'none',
    borderRadius: 10
  };
  const listStyled = {
    width: '100%',
    color: '#000000',
    textAlign: 'left',
    cursor: 'pointer'
  };
  const optionInside = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
    marginLeft: '5%',
    marginRight: '5%'
  }

  const {list, handleSelect, mode} = props;
  const options = [];
  let count = 0;
  for(let k in list){
    const option = list[k];
    const {label, value} = option;
    let listItem = "";
    if(count===0){
      listItem = <div style={{ 
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent:'space-between',
        borderBottomStyle: 'solid',
        borderBottomWidth: 2,
        borderBottomColor: '#cccccc',
        paddingTop: 10,
        marginBottom: 10
      }}>
        <div><img style={{ width: 32, height: 32 }} src={magnifier}/></div>
        <div style={{ marginTop: 5 }}>{label}</div>
        <div><img style={{ width: 16, height: 16, margin: 5 }} src={close}/></div>
      </div>;
      count++;
    }else{
      if(mode==="label-value"){
        listItem = <div style={optionInside} ><div>{label}</div><div>{value}</div></div>;
      }else{
        listItem = <div style={optionInside} >{label}</div>;
      }
    }
    
    options.push(<div key={"k-"+k} style={listStyled} onClick={()=> { handleSelect(k) }}>{listItem}</div>);
  }

  return (
    <div style={selectorFrame}>
      {options}
      {/* <select onChange={handleSelect}>{options}</select> */}
    </div>
  );
}

export default Selector;
