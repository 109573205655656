function Header(props) {
  const {info} = props;
  const {title, logo} = info;
  const logoObj = require("../asset/images/logo/"+logo);
  return (
    <div className="Container">
      <div style={{ width: '100%', display:'flex', flexDirection: 'row', alignItems: 'center', marginBottom: -30 }}>
        <div style={{ width: '40%', marginLeft: '0%' }} onClick={()=>{ document.location.reload() }}><img src={logoObj} style={{ width: 100 }} /></div>
        <div style={{ width: '50%', marginRight: '10%', textAlign: 'left', fontFamily: 'Tahoma', fontSize: 28 }} >{title}</div>
      </div>
    </div>
  );
}

export default Header;