import { useContext, useEffect } from 'react';
import { MainContext } from './main-context';
import Header from './components/Header';

function Flow2c2p() {

  const { primaryData, 
          screen, setScreen,
          paymentUrl, setPaymentUrl,
          setThemeColor
        } = useContext(MainContext);

  useEffect(() => {
    let bgColor = '#000000';
    if(screen === "2c2p"){
      bgColor = '#ffffff';
      document.body.style.backgroundColor = bgColor;
      setThemeColor(bgColor);
    }
  }, [screen]); 
  // If data not ready then return empty (avoid error)
  if(!primaryData) return<></>;

  const handleBack = () => {
    setScreen("payment");
    setPaymentUrl(null);
  }

  return (
    (screen === "2c2p") && 
    <div style={{ backgrounColor: '#ffffff', width: '100%'}}>
      <input type='hidden' id="refOrderNo" value="" />
      {/*<button onClick={()=>{ handleBack() }}>Back</button>*/}
      <iframe name="frame2c2p" id="frame2c2p" width={'100%'} height={600} src={paymentUrl} />
    </div>
  );

}
export default Flow2c2p;
