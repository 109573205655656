import React, {useState, useEffect} from 'react';
import axios from 'axios';
// import logo from './logo.svg';
import './App.css';
import { MainContext } from './main-context';
import Welcome from './Welcome';
import Shops from './Shops';
import Branches from './Branches';
import MenuTypes from './MenuTypes';
import Prices from './Prices';
import Amount from './Amount';
import DateBooking from './DateBooking';
import Tables from './Tables';
import Summary from './Summary';
import Payment from './Payment';
import Flow2c2p from './Flow2c2p';
import { pafekutoAPI, trackingSteps, initSwitchSteps } from './constant';
import { fetchPrimaryData } from './common/api';
import MainPopUp from './components/MainPopUp';

function Reservation() {
    const [primaryData, setPrimaryData] = useState();
  
    useEffect(() => {
      const getPrimary = async () => {
        const primary = await fetchPrimaryData();
        setPrimaryData(primary);
      } 
      getPrimary();
    }, []);
  
    const [screen, setScreen] = useState("welcome");
    const [mainPopUp, setMainPopUp] = useState({
      display: false,
      title: "",
      detail: [],
      width: 350,
      height: 200
    });
    const [shop, setShop] = useState(null);
    const [branch, setBranch] = useState(null);
    const [menuType, setMenuType] = useState(null);
    const [price, setPrice] = useState(null);
    const [amount, setAmount] = useState(null);
    const [bookingDate, setBookingDate] = useState(null);
    const [bookingTime, setBookingTime] = useState(null);
    const [slot, setSlot] = useState(null);
    const [table, setTable] = useState(null);
    const [tableName, setTableName] = useState(null);
    const [tableDetail, setTableDetail] = useState({});
    const [tableTypeInfo, setTableTypeInfo] = useState({});
    const [customerNameSurname, setCustomerNameSurname] = useState(null);
    const [customerTel, setCustomerTel] = useState(null);
    const [customerEmail, setCustomerEmail] = useState(null);
    const [customerRemark, setCustomerRemark] = useState(null);
    const [reserveSteps, setReserveSteps] = useState([]);
    const [swichOnOffSteps, setSwichOnOffSteps] = useState(initSwitchSteps);
    const [paymentUrl, setPaymentUrl] = useState(null);
    const [paymentSource, setPaymentSource] = useState("PPQR");
    const [themeColor, setThemeColor] = useState("#000000");
  
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    console.log("# windowWidth: ", windowWidth);
    console.log("# windowHeight: ", windowHeight);
  
    let refWidth = windowWidth;
    if(windowWidth > 500) refWidth = 500;
    
    return (<div style={{
        width: refWidth,
        height: windowHeight,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColor
      }}>
      <MainContext.Provider value={{
            screen, setScreen,
            mainPopUp, setMainPopUp,
            shop, setShop,
            branch, setBranch,
            menuType, setMenuType,
            price, setPrice,
            amount, setAmount,
            bookingDate, setBookingDate,
            bookingTime, setBookingTime,
            slot, setSlot,
            table, setTable,
            tableName, setTableName,
            tableDetail, setTableDetail,
            tableTypeInfo, setTableTypeInfo,
            reserveSteps, setReserveSteps,
            swichOnOffSteps, setSwichOnOffSteps,
            paymentUrl, setPaymentUrl,
            customerNameSurname,setCustomerNameSurname,
            customerTel, setCustomerTel,
            customerEmail, setCustomerEmail,
            customerRemark, setCustomerRemark,
            themeColor, setThemeColor,
            paymentSource, setPaymentSource,
            primaryData
          }} >
          <MainPopUp/>
          <Welcome/>
          <Shops/>
          <Branches/>
          <MenuTypes/>
          <Prices/>
          <Amount/>
          <DateBooking/>
          <Tables/>
          <Summary/>
          <Payment/>
          <form id='formPayment' method='post' action={paymentUrl} target="frame2c2p" >
            <Flow2c2p/>
          </form>
        </MainContext.Provider>
      </div>);
  }

  export default Reservation;