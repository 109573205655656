import { useContext } from 'react';
import { MainContext } from './main-context';
import Header from './components/Header';
import Selector from './components/Selector';

function Branches() {
  // Get global data from context
  const { primaryData, shop, menuType, setShop, screen, setScreen, setBranch } = useContext(MainContext);
  
  // If data not ready then return empty (avoid error)
  if((!primaryData)||[shop].includes(null)) return<></>;
  
  // Destruct important data
  const { shops, menuTypes } = primaryData;
  const { branch } = shops[shop];

  // Create Header info
  let shopName = null;
  let shopLogo = null;
  if((screen != "welcome")&&(screen != "shop")){
    shopName = primaryData.shops[shop].label; 
    shopLogo = primaryData.shops[shop].logo; 
  }
  const headerInfo = {
    title: shopName,
    logo: shopLogo
  };

  // Create root object for the first of selector
  const rootObj = { root: { 
    key: 'root',
    label: shops[shop].label
  }};

  // Create handle function for event change
  const handleSelect = (value) => {
    if(value=="root"){
      setScreen("shop");
      // setShop("root");
    }else{
      const getBranch = value;
      const key = shop+"___"+getBranch;
      if(typeof menuTypes[key] === 'undefined'){
        alert("No DATA!");
        return;
      }
      setScreen("menu-types");
      setBranch(value);
    }
  }

  return (
    (screen == "branch") && 
    <div className="Container">
      <Header info={headerInfo}/>
      <Selector list={{...rootObj, ...branch}} handleSelect={handleSelect} />
    </div>
  );
}

export default Branches;
