import { useContext } from 'react';
import { MainContext } from './main-context';
import Header from './components/Header';
import Selector from './components/Selector';

function Shops() {
  // Get global data from context
  const { primaryData, setShop, screen, setScreen } = useContext(MainContext);
  
  // If data not ready then return empty (avoid error)
  if(!primaryData) return<></>;

  // Destruct important data
  const { shops } = primaryData;

  // Create Header info
  let shopName = "Pafekuto Group";
  let shopLogo = "pafekuto.jpg";
  const headerInfo = {
    title: shopName,
    logo: shopLogo
  };

  // Create root object for the first of selector
  const rootObj = { root: { 
    key: 'root',
    label: 'Pafekuto Group',
    logo:  'pafekuto.jpg'
  }};

  // Create handle function for event change
  const handleSelect = (value) => {
    if(value=="root"){
      setScreen("welcome");
      // setShop("root");
    }else{
      setScreen("branch");
      setShop(value);
    }
  }

  // Render components
  return (
    (screen == "shop") && 
    <div className="Container">
      <Header info={headerInfo}/>
      <Selector list={{...rootObj, ...shops}} handleSelect={handleSelect} />
    </div>
  ); 
}

export default Shops;
