import { useContext } from 'react';
import { MainContext } from './main-context';
import Header from './components/Header';
import Steps from './components/Steps';
import Selector from './components/Selector';

function Prices() {
  // Get global data from context
  const { primaryData, shop, screen, setScreen, branch, menuType, setPrice, reserveStep, setReserveStep } = useContext(MainContext);

  // If data not ready then return empty (avoid error)
  if((!primaryData)||[shop,branch,menuType].includes(null)) return<></>;
  
  // Destruct important data
  const { shops, menuTypes } = primaryData;

  // Create key name for menuTypes
  const keyMenu = shop+"___"+branch;
  console.log("# keyMenu: ", keyMenu);
  console.log("# menuType: ", menuType);
  console.log("# objTest: ", menuTypes[keyMenu][menuType]);
  const { prices, label: labelMenu } = menuTypes[keyMenu][menuType];
  
  const labelShopBranchMenu = shops[shop].branch[branch].label+" "+labelMenu;

  // Create Header info
  let shopName = null;
  let shopLogo = null;
  if((screen != "welcome")&&(screen != "shop")){
    shopName = labelShopBranchMenu;
    shopLogo = shops[shop].logo;
  }
  const headerInfo = {
    title: shopName,
    logo: shopLogo
  };

  // Create root object for the first of selector
  const rootObj = { root: { 
    key: 'root',
    label: labelShopBranchMenu
  }};

  // Create handle function for event change
  const handleSelect = (value) => {
    if(value=="root"){
      setScreen("menu-types");
    }else{
      setScreen("amount");
      setPrice(value); 
    }
  }

  return (
    (screen === "prices") && 
    <div className="Container">
      <Header info={headerInfo}/>
      <Steps/>
      <div style={{ marginTop: -30 }} >
        <Selector list={{...rootObj, ...prices}} handleSelect={handleSelect} mode={"label-value"} />
      </div>
    </div>
  );
}

export default Prices;
