const env = 'prod';

export const pafekutoAPI = (env==='prod'?"https://reserve.pafekutogroup.com:3000":"http://localhost:3000");
export const trackingSteps = { 
    default: ["prices", "amount", "date-booking"],
    alacarte: ["amount", "date-booking"]
};
export const initSwitchSteps = {
    "prices": false,
    "amount": false,
    "date-booking": false
};
export const contactStaffPopUp = {
    many: {
        title: "โปรดติดต่อสตาฟในการจองจำนวนมาก",
        detail: [
            "Tel: 020962888 กด 1",
            "Line: @oranjii"
        ],
        width: 320,
        height: 200
    },
    vip: {
        title: "โปรดติดต่อสตาฟในการจองโต๊ะพิเศษ",
        detail: [
            "Tel: 020962888 กด 1",
            "Line: @oranjii"
        ],
        width: 320,
        height: 200
    }
}
/* export const dataRef = {
    company : {
        label: 'Pafekuto Group', 
        logo: 'pafekuto.jpg',
        bgcolor: '#000000'
    },
    shops: {
        oranjii: { 
            label: 'ORANJII', 
            key : 'oranjii',
            logo: 'oranjii-gold.jpg',
            bgcolor: '#000000',
            branch: {
                phahon11: { label: 'ORANJII Phahon11', key: 'phahon11' },
                saphankwai: { label: 'ORANJII Saphankwai', key: 'saphankwai' }
            }
        },
        hwantsu: { 
            label: 'Hwantsu', 
            key: 'hwantsu',
            logo: 'hwantsu.jpg',
            bgcolor: '#677a42',
            branch: {
                phahon11: { label: 'Hwantsu Phahon11', key: 'phahon11' },
                saphankwai: { label: 'Hwantsu Saphankwai', key: 'saphankwai' }
            }
        },
        omakase: { 
            label: 'Pafekuto Omakase', 
            key: 'omakase',
            logo: 'omakase.jpg',
            bgcolor: '#1f1f1f',
            branch: {
                phahon11: { label: 'Pafekuto Omakase Phahon11', key: 'phahon11' },
                saphankwai: { label: 'Pafekuto Omakase Saphankwai', key: 'saphankwai' }
            }
        },
        ramen: { 
            label: 'Sentsu Ramen', 
            key: 'ramen',
            logo: 'sentsu.jpg',
            bgcolor: '#000000',
            branch: {
                phahon11: { label: 'Sentsu Ramen Phahon11', key: 'phahon11' },
                saphankwai: { label: 'Sentsu Ramen Saphankwai', key: 'saphankwai' }
            }
        },
        ican: { 
            label: 'I can | Chef Table', 
            key: 'ican',
            logo: 'ican.jpg',
            bgcolor: '#142345',
            branch: {
                phahon11: { label: 'I can | Chef Table Phahon11', key: 'phahon11' },
                saphankwai: { label: 'I can | Chef Table Saphankwai', key: 'saphankwai' }
            }
        },
        train: { 
            label: 'Take A Train', 
            key: 'train',
            logo: 'train-blue.jpg',
            bgcolor: '#416e8d',
            branch: {
                phahon11: { label: 'Take A Train Phahon11', key: 'phahon11' },
                saphankwai: { label: 'Take A Train Saphankwai', key: 'saphankwai' }
            }
        }
    },
    //------ MENU TYPES ------
    menuTypes: {
        oranjii___phahon11: {
            info: { 
                label: 'ORANJII Phahon11', 
                key: 'root',
                prices: {}
            },
            types: {
                buffet: { 
                    label: 'ORANJII Buffet', 
                    key: 'buffet',
                    prices: {
                        root: { label: 'ORANJII Phahon11 Buffet', key: 'root' },
                        premium_399: { label: 'Premium Set 399.-', key: 'premium_399' },
                        platinum_599: { label: 'Platinum Set 599.-', key: 'platinum_599' },
                        prestige_899: { label: 'Prestige Set 899.-', key: 'prestige_899' }
                    }
                },
                alacarte: { 
                    label: 'ORANJII A La Carte', 
                    key: 'alacarte',
                    prices: {}
                }
            }
        }
    }
}

export const initState = {
    screen: 'welcome',
    shop: 'oranjii',
    branch: 'phahon11',
    menuType: 'buffet',
    price: null,
    amount: null,
    bookingDate: new Date(),
    slot: null,
    table: null,
    reserveStep: 1,
    customerNameSurname: null,
    customerTel: null,
    customerEmail: "",
    customerRemark: "",
}
*/