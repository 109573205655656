export const convertDate = (dateIn) => {
    var mm = dateIn.getMonth() + 1; // getMonth() is zero-based
    var dd = dateIn.getDate();
  
    return [dateIn.getFullYear(),
            (mm>9 ? '' : '0') + mm,
            (dd>9 ? '' : '0') + dd
           ].join('-');
}

export const convertToDateDisplay = (date_yyyymmdd) => {
    const aDate = date_yyyymmdd.split("-");
    return [aDate[2],aDate[1],aDate[0]].join('/');
}