import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import Popup from 'reactjs-popup';
import "react-datepicker/dist/react-datepicker.css";
import { 
            fetchPrimaryData, 
            fetchTemplatesTime, 
            fetchTableList, 
            fetchTransactions, 
            cancelTransaction,
            createTransaction,
            deleteTransaction,
            setPaidTransaction
        } from '../common/api';
import { convertDate } from '../common/functions';


function Transactions() {

    const [startDate, setStartDate] = useState(new Date());
    const [primaryData, setPrimaryData] = useState({});
    const [shopActive, setShopActive] = useState(null);
    const [branchActive, setBranchActive] = useState(null);
    const [menuTypeActive, setMenuTypeActive] = useState(null);
    const [priceActive, setPriceActive] = useState(null);
    const [tableActive, setTableActive] = useState(null);
    const [timeActive, setTimeActive] = useState(null);
    const [amountActive, setAmountActive] = useState(1);
    const [totalActive, setTotalActive] = useState(0);
    const [templatesTime, setTemplatesTime] = useState([]);
    const [tables, setTables] = useState([]);
    const [txs, setTxs] = useState([]);

    const [formDeposit, setFormDeposit] = useState(null);
    const [formFullname, setFormFullname] = useState(null);
    const [formTel, setFormTel] = useState(null);
    const [formEmail, setFormEmail] = useState(null);
    const [formRemark, setFormRemark] = useState(null);

    const [popupAction_tx, setPopupAction_tx] = useState(null);
    const [popupAction_table, setPopupAction_table] = useState(null);
    const [popupAction_status, setPopupAction_status] = useState(null);
    const [popupAction_info, setPopupAction_info] = useState({});

    const [popupPaidAmount, setPopupPaidAmount] = useState(0);

    const [renderShopList, setRenderShopList] = useState([]);
    const [renderBranchList, setRenderBranchList] = useState([]);
    const [renderMenuTypeList, setRenderMenuTypeList] = useState([]);
    const [renderPriceList, setRenderPriceList] = useState([]);
    const [renderTableList, setRenderTableList] = useState([]);
    const [renderTimeList, setRenderTimeList] = useState([]);
    const [renderAmountList, setRenderAmountList] = useState([]);

    const refPopUpNew = useRef();
    const openPopupNew = () => {
        setFormDeposit(null);
        setFormFullname(null);
        setFormTel(null);
        setFormEmail(null);
        setFormRemark(null);
        refPopUpNew.current.open();
    }
    const closePopupNew = () => refPopUpNew.current.close();

    const refPopUpAction = useRef();
    const openPopupAction = (info, status) =>{
        const { table_code, tx_code } = info;
        setPopupAction_tx(tx_code);
        setPopupAction_table(table_code);
        setPopupAction_status(status);
        setPopupAction_info(info);
        refPopUpAction.current.open();
    }
    const closePopupAction = () => refPopUpAction.current.close();
    
    const refPopUpConfirmDelete = useRef();
    const openPopupConfirmDelete = () =>{
        refPopUpConfirmDelete.current.open();
    }
    const closePopupConfirmDelete = () => refPopUpConfirmDelete.current.close();
    
    const refPopUpPaid = useRef();
    const openPopupPaid = () =>{
        refPopUpPaid.current.open();
    }
    const closePopupPaid = () => refPopUpPaid.current.close();

    useEffect(() => {
        const getPrimary = async () => {
            const primary = await fetchPrimaryData();
            setPrimaryData(primary);
            setShopActive("oranjii");
            setBranchActive("saphankwai");
        }
        const getTemplatesTime = async () => {
            const times = await fetchTemplatesTime();
            if(times.length < 1) return;
            setTemplatesTime(times);
            if(!timeActive) setTimeActive(times[0].code);
            const timeList = [];
            for(const _t of times){
                const {code, name, start, end, status} = _t;
                timeList.push(<option key={`time-${code}`} value={code}>{`Start: ${start}, End: ${end}`}</option>);
            }
            setRenderTimeList([...timeList]);
        } 

        const createAmountList = () => {
            const amountList = [];
            for(let x=1; x<=15; x++){
                amountList.push(<option key={`amount-${x}`} value={x}>{x}</option>);
            }
            setRenderAmountList([...amountList]);
        }
        getPrimary();
        getTemplatesTime();
        createAmountList();
    }, []);

    useEffect(()=>{
        const { shops=null } = primaryData;

        const shopsList = [];
        const branchList = [];
        for(const _shop in shops){
            const { key, label, branch } = shops[_shop];
            shopsList.push(<option key={`shop-${key}`} value={key}>{label}</option>);
            for(const _branch in branch){
                const { key, label } = branch[_branch];
                branchList.push(<option key={`branch-${key}`} value={key}>{label}</option>);
            }
        }
        setRenderShopList([...shopsList]);
        setRenderBranchList([...branchList]);
    }, [primaryData]);

    useEffect(()=>{
        const getTableList = async () => {
            const data = await fetchTableList(shopActive, branchActive, "");
            setTables(data);
        }
        const getTransactions = async () =>{
            const tx = await fetchTransactions(shopActive, branchActive, convertDate(startDate));
            console.log("# transactions: ", tx);
            setTxs(tx);
        }
        if(shopActive && branchActive){
            //alert(`${shopActive}___${branchActive}`);
            getTableList();
            getTransactions();
            const { menuTypes } = primaryData;
            try{
                setMenuTypeActive(null);
                const refMenuTypes = menuTypes[`${shopActive}___${branchActive}`];
                //console.log("# refMenuTypes: ", refMenuTypes);
                const menuTypeList = [];
                const defaultSelect = [];
                for(const _mt in refMenuTypes){
                    const { key, label } = refMenuTypes[_mt];
                    if(key!=='alacarte'){
                        if(defaultSelect.length<1) defaultSelect.push(key);
                        menuTypeList.push(<option key={`mt-${key}`} value={key}>{label}</option>);
                    }
                }
                //console.log("# menuTypeList: ", menuTypeList);
                setMenuTypeActive(defaultSelect.pop());
                setRenderMenuTypeList([...menuTypeList]);
            }catch(err){}
        }
    }, [shopActive, branchActive, startDate]);

    useEffect(()=>{
        const { menuTypes } = primaryData;
        try{
            setPriceActive(null);
            const refPrices = menuTypes[`${shopActive}___${branchActive}`][menuTypeActive]['prices'];
            const priceList = [];
            const defaultSelect = [];
            for(const _p in refPrices){
                const { key, label, value } = refPrices[_p];
                if(defaultSelect.length<1) defaultSelect.push(key);
                priceList.push(<option key={`price-${key}`} value={key}>{`${label} (${value} บาท)`}</option>);
            }
            setPriceActive(defaultSelect.pop());
            setRenderPriceList([...priceList]);
        }catch(err){}
    }, [menuTypeActive]);

    useEffect(()=>{
        if(menuTypeActive !== 'alacarte'){
            const { menuTypes } = primaryData;
            try{
                const refPrices = menuTypes[`${shopActive}___${branchActive}`][menuTypeActive]['prices'];
                const { value } = refPrices[priceActive];
                const unitValue = parseFloat(value);
                const am = parseInt(amountActive);
                setTotalActive(unitValue * am);
            }catch(err){}
        }
    }, [priceActive, amountActive]);
    
    useEffect(()=>{
        const tableList = [];
        const defaultSelect = [];
        for(const _table of tables){
            const {code,name} = _table;
            if(defaultSelect.length<1) defaultSelect.push(code);
            tableList.push(<option value={code}>{name}</option>);
        }
        setTableActive(defaultSelect.pop());
        setRenderTableList([...tableList]);
    }, [tables])

    const renderTableBody = () => {
        const renderRows = [];
        if(txs.length<1){
            return <div className="empty">No Data</div>;
        }
        for(const _r of txs){
            let status = "wait";
            if(_r.source && _r.paid_time) status = "paid";
            if(_r.cancel_time) status = "cancel";
            renderRows.push(<div className="row">
                <div className="data" >
                    {status!=='cancel'&&<button onClick={()=>{ openPopupAction(_r, status ) }}>..</button>}
                </div>
                <div className="data" >{_r.table_code}</div>
                <div className="data" >{_r.offer_type}</div>
                <div className="data" >{_r.package}</div>
                <div className="data" >{_r.amount}</div>
                <div className="data" >{_r.name}</div>
                <div className="data" >{_r.tel}</div>
                <div className={`data status-${status}`} >{status}</div>
                <div className="data" >{_r.source}</div>
                <div className="data" >{_r.tx_code}</div>
                <div className="data" >{_r.total_price}</div>
                <div className="data" >{_r.reserve_price}</div>
                <div className="data" >{'-'}</div>
                <div className="data" >{_r.env}</div>
                <div className="data" >{_r.merchant_id}</div>
                <div className="data" >{_r.merchant_name}</div>
                {/*<div className="data" >{'-'}</div>*/}
            </div>);
        }

        return renderRows;
    }

    const toCancel = async (txcode) => {
        if(window.confirm(`ต้องการยกเลิก: ${txcode}?`)){
            closePopupAction();
            //setTxs([]);
            const response = await cancelTransaction(txcode, '-');
            const {success=false} = response;
            if(!success){
                alert("Cancel error!");
                return;
            }
            const dataTx = await fetchTransactions(shopActive, branchActive, convertDate(startDate));
            setTxs(dataTx);
        }
    }
    const toReserve = async () => {
        const info = {
            "bookingDate": convertDate(startDate),
            "bookingTime": timeActive,
            "table": tableActive,
            "customerNameSurname": formFullname,
            "customerTel": formTel,
            "customerEmail": formEmail,
            "customerRemark": formRemark,
            "reserveValue": formDeposit,
            "totalValue": totalActive,
            "shop": shopActive,
            "branch": branchActive,
            "menuType": menuTypeActive,
            "price": priceActive,
            "amount": amountActive
        }
        const response = await createTransaction(info);
        console.log("# created tx: ", response);
        const { success = false} = response;
        if(success){
            closePopupNew();
            const dataTx = await fetchTransactions(shopActive, branchActive, convertDate(startDate));
            setTxs(dataTx);
        }
    }
    const toDelete = async (txcode) => {
        closePopupConfirmDelete();
        const response = await deleteTransaction(txcode);
        const {success=false} = response;
        if(!success){
            alert("Delete error!");
            return;
        }
        const dataTx = await fetchTransactions(shopActive, branchActive, convertDate(startDate));
        setTxs(dataTx);
    }
    const toPaid = () => {
        setPopupPaidAmount(popupAction_info.reserve_price);
        closePopupAction();
        openPopupPaid();
    }
    const toConfirmPaid = async () => {
        closePopupPaid();
        const info = {
            txcode: popupAction_tx,
            paid: popupPaidAmount
        }

        const response = await setPaidTransaction(info);
        const {success=false} = response;
        if(!success){
            alert("Set Paid error!");
            return;
        }
        const dataTx = await fetchTransactions(shopActive, branchActive, convertDate(startDate));
        setTxs(dataTx);
    }
    
    return <>
    <Popup 
      ref={refPopUpConfirmDelete} 
      modal
      contentStyle={{
        width: 'auto',
        height: 'auto',
        borderRadius: 10
      }}
    >
        <div className="Popup-confirm-delete">
            <div className="content">ยืนยันในการลบ {popupAction_tx}?</div>
            <div className="buttons">
                <button onClick={()=>{ toDelete(popupAction_tx); }}>YES</button>
                <button onClick={()=>{ closePopupConfirmDelete(); }}>NO</button>
            </div>
        </div>
    </Popup>
    <Popup
        ref={refPopUpPaid}
        modal
        contentStyle={{
          width: 'auto',
          height: 'auto',
          borderRadius: 5
        }}
    >
        <div className="Popup-paid">
            <div className="content" >โต๊ะที่: {popupAction_table}</div>
            <div className="content" >Ref Code: {popupAction_tx}</div>
            <div className="content" >จำนวนเงิน: {popupAction_info.total_price}</div>
            <div className="row-input">
                <div className="label">จ่ายแล้ว: </div>
                <div className="value"><input 
                    type='number' 
                    value={popupPaidAmount} 
                    onChange={(event)=>{
                        setPopupPaidAmount(event.target.value);
                    }}
                /></div>
            </div>
            <div className="buttons">
                <button onClick={()=>{ toConfirmPaid(); }}>Confirm</button>
                <button onClick={()=>{ closePopupPaid(); }}>Exit</button>
            </div>
        </div>
    </Popup>
    <Popup
        ref={refPopUpAction}
        modal
        contentStyle={{
          width: 'auto',
          height: 'auto',
          borderRadius: 5
        }}
    >
        <div className="Popup-action">
            <div>Table: {popupAction_table}</div>
            <div>Tx Code: {popupAction_tx}</div>
            {popupAction_status==='wait' && <div><button className="bt-paid" onClick={()=>{ toPaid()} }>จ่ายแล้ว</button></div>}
            {popupAction_status!=='wait' && <div><button className="bt-cancel" onClick={()=>{ toCancel(popupAction_tx)} }>ยกเลิก</button></div>}
            {popupAction_status==='wait' && <div><button className="bt-discard" onClick={()=>{ 
                closePopupAction();
                openPopupConfirmDelete(); 
            }} >ลบทิ้ง</button></div>}
        </div>
    </Popup>
    <Popup 
      ref={refPopUpNew} 
      modal
      contentStyle={{
        width: 'auto',
        height: 'auto',
        borderRadius: 10
      }}
    >
        <div className="Popup-Admin">
            <div className="block-input" >
                <div className="label" >Date:</div>
                <div className="input" >{startDate.toDateString()}</div>
            </div>
            <div className="block-input" >
                <div className="label" >Shop:</div>
                <div className="input" >{shopActive}</div>
            </div>
            <div className="block-input" >
                <div className="label" >Branch:</div>
                <div className="input" >{branchActive}</div>
            </div>
            <div className="block-input" >
                <div className="label" >Menu Type:</div>
                <div className="input" >
                    <select value={menuTypeActive} onChange={(event)=>{ setMenuTypeActive(event.target.value); }} >{renderMenuTypeList}</select>
                </div>
            </div>
            <div className="block-input" >
                <div className="label" >Menu Set:</div>
                <div className="input" >
                    <select value={priceActive} onChange={(event)=>{ 
                        setPriceActive(event.target.value); }} 
                    >{renderPriceList}</select>
                </div>
            </div>
            <div className="block-input" >
                <div className="label" >Table:</div>
                <div className="input" >
                    <select value={tableActive} onChange={(event)=>{ setTableActive(event.target.value); }} >{renderTableList}</select>
                </div>
            </div>
            <div className="block-input" >
                <div className="label" >Time:</div>
                <div className="input" >
                    <select value={timeActive} onChange={(event)=>{ setTimeActive(event.target.value); }} >{renderTimeList}</select>
                </div>
            </div>
            <div className="block-input" >
                <div className="label" >Amount:</div>
                <div className="input" >
                    <select value={amountActive} onChange={(event)=>{ setAmountActive(parseInt(event.target.value)); }} >{renderAmountList}</select>
                </div>
            </div>
            <div className="block-input" >
                <div className="label" >Total:</div>
                <div className="input" >{`${totalActive} Baht`}</div>
            </div>
            <div className="block-input" >
                <div className="label" >Deposit:</div>
                <div className="input" ><input type='number' value={formDeposit} onChange={(event)=>{ setFormDeposit(event.target.value) }} /></div>
            </div>
            <div className="block-input" >
                <div className="label" >Full Name:</div>
                <div className="input" ><input type='text' value={formFullname} onChange={(event)=>{ setFormFullname(event.target.value) }} /></div>
            </div>
            <div className="block-input" >
                <div className="label" >Tel :</div>
                <div className="input" ><input type='text' value={formTel} onChange={(event)=>{ setFormTel(event.target.value) }} /></div>
            </div>
            <div className="block-input" >
                <div className="label" >Email :</div>
                <div className="input" ><input type='text' value={formEmail} onChange={(event)=>{ setFormEmail(event.target.value) }} /></div>
            </div>
            <div className="block-input" >
                <div className="label" >Remark :</div>
                <div className="input" ><input type='text' value={formRemark} onChange={(event)=>{ setFormRemark(event.target.value) }} /></div>
            </div>
            <div style={{
                marginTop: '20px',
                marginBottom: '5px',
                textAlign: 'center'
            }}>
                <button style={{
                    padding: '10px',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}
                    onClick={()=>{ toReserve() }}
                >Reserve</button>
            </div>
        </div>
    </Popup>
    <div className="Container-Admin" >
        <div className='block-new-button' >
            <button onClick={()=>{ openPopupNew() }}>New Reserve</button>
        </div>
        <div className="title" >Reserve Transactions</div>
        <div className='block-input'>
            <div className='label'>Date:</div>
            <div className='input'>
                <DatePicker 
                    selected={startDate} 
                    onChange={(date) => setStartDate(date)} 
                    dateFormat={'dd/MM/yyyy'}
                />
            </div>
        </div>
        <div className='block-input'>
            <div className='label'>Shop:</div>
            <div className='input'>
                <select 
                    value={shopActive}
                    onChange={(value)=>{
                        //alert(value);
                    }}
                >{renderShopList}</select>
            </div>
        </div>
        <div className='block-input'>
            <div className='label'>Branch:</div>
            <div className='input'>
                <select 
                    value={branchActive} 
                    onChange={(value)=>{
                        //alert(value);
                    }}
                >{renderBranchList}</select>
            </div>
        </div>
        <div className='table'>
            <div className='header' >
                <div></div>
                <div>Table</div>
                <div>Type</div>
                <div>Price</div>
                <div>Amount</div>
                <div>Name</div>
                <div>Tel</div>
                <div>Status</div>
                <div>Source</div>
                <div>Invoice</div>
                <div>Total</div>
                <div>Deposit</div>
                <div>Success</div>
                <div>System</div>
                <div>Merchant ID</div>
                <div>Merchant Name</div>
                {/* <div>Slip</div> */}
            </div>
            <div className='body' >
                {renderTableBody()}
            </div>
        </div>
    </div>
    </>;
}

export default Transactions;