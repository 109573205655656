import axios from 'axios';
import { pafekutoAPI } from '../constant';

export const fetchPrimaryData = async () => {
  const response = await axios.post(pafekutoAPI+"/get-data-ref");
  console.log("Init app");
  console.log("# response.data: ", response.data);
  //setPrimaryData(response.data);
  return response.data;
}

export const fetchTemplatesTime = async () => {
  const response = await axios.post(pafekutoAPI+"/get-templates-time");
  return response.data;
}

export const fetchTableList = async (shop, branch, menuType) => {
  const response = await axios.post(`${pafekutoAPI}/get-tables-list`, {
    shop,
    branch,
    menuType
  });
  return response.data;
}

export const getTableInfo = async (type_code) => {
  const response = await axios.post(`${pafekutoAPI}/get-table-info`, {
    type_code
  });
  return response.data;
}

export const fetchTransactions = async (shop, branch, bookingDate) => {
  const response = await axios.post(`${pafekutoAPI}/get-tx`, {
    shop,
    branch,
    bookingDate
  });
  return response.data;
}

export const cancelTransaction = async (txcode, reason) => {
  const response = await axios.post(`${pafekutoAPI}/admin/cancel`, {
    txcode,
    reason
  });
  return response.data;
}

export const createTransaction = async (info) => {
  const response = await axios.post(`${pafekutoAPI}/admin/reserve`, {
    info
  });
  return response.data;
}

export const deleteTransaction = async (txcode) => {
  const response = await axios.post(`${pafekutoAPI}/admin/delete`, {
    txcode
  });
  return response.data;
}

export const setPaidTransaction = async (info) => {
  const response = await axios.post(`${pafekutoAPI}/admin/paid`, {
    info
  });
  return response.data;
}

export const login = async (username, password) => {
  //console.log("# username: ", username);
  //console.log("# password: ", password);
  //return;
  const response = await axios.post(`${pafekutoAPI}/login`, {
    username,
    password
  });
  return response.data;
}