import { useContext, useEffect, useRef } from 'react';
import { MainContext } from '../main-context';
import Popup from 'reactjs-popup';

function MainPopUp() {

    const { mainPopUp, setMainPopUp } = useContext(MainContext);
    const { display, title, detail, width, height } = mainPopUp;
    const refPopUpContactStaff = useRef();
    const openPopup = () => refPopUpContactStaff.current.open();
    const closePopup = () => refPopUpContactStaff.current.close();

    useEffect(()=>{
        if(display) openPopup();
        else closePopup();
    },[display]);

    const detailList = [];
    for(let x in detail){
        detailList.push(<div key={`pop-detail-${x}`} className='detail' >{detail[x]}</div>);
    }

    return <Popup 
        ref={refPopUpContactStaff} 
        modal
        contentStyle={{
        width: width,
        height: height,
        borderRadius: 10
        }}
    > 
        <div className="Popup-Main" >
            <div className='title' >{title}</div>
            {detailList}
            <div>
                <div className='button' onClick={()=>{ setMainPopUp({...mainPopUp, display: false }) }}>ปิด</div>
            </div>
        </div>
    </Popup>
}

export default MainPopUp;