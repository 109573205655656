import { useContext, useEffect } from 'react';
import { MainContext } from './main-context';
import { getTableInfo } from './common/api';
import { convertToDateDisplay } from './common/functions';
import axios from 'axios';
import Header from './components/Header';
import Steps from './components/Steps';

function Summary() {
  
  const { primaryData, 
    screen, setScreen,
    shop, setShop, 
    branch, setBranch,
    menuType, setMenuType,
    price, setPrice,
    customerNameSurname, setCustomerNameSurname,
    customerTel, setCustomerTel,
    customerEmail, setCustomerEmail,
    customerRemark, setCustomerRemark,
    bookingDate,
    bookingTime,
    table,
    tableName,
    tableDetail,
    tableTypeInfo,
    setTableTypeInfo
  } = useContext(MainContext);

  useEffect(() => {
    if(screen!=='summary') return;
    const { table_type } = tableDetail;
    getSelectedTableInfo(table_type);
  },[screen]);

  const getSelectedTableInfo = async (type_code) => {
    const data = await getTableInfo(type_code);
    console.log("# table info: ", data);
    if(!data) return;
    if(data.length<=0) return;
    setTableTypeInfo(data[0]);
  }
  
  // If data not ready then return empty (avoid error)
  if((!primaryData)||[shop,branch,menuType,tableTypeInfo,bookingTime].includes(null)) return<></>;

  const textField = (icon, placeholder, field) => {
    return <div style={{
      display: 'flex',
      flexDirection: 'row',
      borderBottomStyle: 'solid',
      borderBottomColor: '#ffffff',
      borderBottomWidth: '1px',
      paddingBottom: 7,
      marginBottom: 15
    }}>
      <img src={icon} 
        style={{ height: 24 }}
      /><input 
          type='text' 
          style={{ 
            width: '100%', 
            marginLeft: 10, 
            borderStyle: 'none', 
            background: 'none', 
            color: '#ffffff', 
            fontSize: 16
          }} 
          placeholder={placeholder} 
          onChange={(event)=>{
            const {value} = event.target;
            if(field === "name_surname"){
              setCustomerNameSurname(value);
            }else if(field === "tel"){
              setCustomerTel(value);
            }
            else if(field === "email"){
              setCustomerEmail(value);
            }
            else if(field === "remark"){
              setCustomerRemark(value);
            }
          }}
        />
    </div>
  }

  const textInfo = (icon, label, value) => {
    return <div style={{ width: '50%', display: 'flex', flexDirection: 'row', fontSize: 12, marginBottom: 15, fontFamily: 'sans-serif' }} >
      <div><img 
        src={icon} 
        style={{ height: 28 }} 
      /></div>
      <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'column', textAlign: 'left' }} >
        <div>{label}</div>
        <div style={{ color: '#E74C3C' }} >{value}</div>
      </div>
    </div>
  }

  // Destruct important data
  const { shops, menuTypes } = primaryData;
  const { pan_name='' } = tableTypeInfo;
  const { start='', end='' } = bookingTime;
  
  // Create key name for menuTypes
  const keyMenu = shop+"___"+branch;
  const { prices, label: labelMenu } = menuTypes[keyMenu][menuType];
  const labelShopBranchMenu = shops[shop].branch[branch].label+" "+labelMenu; 

  // Create Header info
  let shopName = null;
  let shopLogo = null;
  if((screen != "welcome")&&(screen != "shop")){
    shopName = labelShopBranchMenu;
    shopLogo = shops[shop].logo; 
  }
  const headerInfo = {
    title: shopName,
    logo: shopLogo
  };

  const checkBeforePayment = () => {
    let status = true;
    if((customerNameSurname==="")||(customerTel==="")) status=false;
    return status;
  }
  
  return (
    (screen == "summary") && 
    <div className="Container">
      <Header info={headerInfo}/>
      {/*<Steps/>*/}
      <div style={{ 
        width: '80%',
        marginLeft: '10%',
        marginRight: '10%',
        paddingBottom: 10,
        
      }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '30px'
      }}>
        <button style={{
          padding: 10,
          backgroundColor: '#cccccc',
          color: '#333333',
          fontWeight: 'bold',
          fontSize: 14,
          borderStyle: 'none',
          borderRadius: 5,
          cursor: 'pointer'
        }} onClick={()=>{ 
          setScreen("date-booking");
        }}>กลับไปเลือกใหม่</button>
        <button style={{
          padding: 10,
          backgroundColor: '#ff0000',
          color: '#ffffff',
          fontWeight: 'bold',
          fontSize: 14,
          borderStyle: 'none',
          borderRadius: 5,
          cursor: 'pointer'
        }} onClick={()=>{ 
          if(checkBeforePayment()) setScreen("payment");
          else alert("โปรดกรอกชื่อ-นามสกุล และเบอร์โทรศัพท์ผู้จอง") 
        }}>สรุปยอดชำระเงิน</button>
      </div>
      {textField(require(`./asset/images/summary/user.png`), "ชื่อ-นามสกุล ผู้จอง", 'name_surname')}
      {textField(require(`./asset/images/summary/phone.png`), "เบอร์โทรติดต่อ", 'tel')}
      {textField(require(`./asset/images/summary/mail.png`), "อีเมล", 'email')}
      {textField(require(`./asset/images/summary/message.png`), "คำขอพิเศษ / โอกาสพิเศษ", 'remark')}
      </div>
      <div style={{ 
        width: '80%',
        marginLeft: '10%',
        marginRight: '10%',
        paddingTop: 10
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'left',
          width: '100%',
          marginTop: -10
        }}>
        {textInfo(require(`./asset/images/summary/calendar.png`), "วันที่", convertToDateDisplay(bookingDate))}
        {textInfo(require(`./asset/images/summary/clock.png`), "เวลา", `${start} - ${end}`)}
        {textInfo(require(`./asset/images/summary/table.png`), "โต๊ะ", tableName)}
        {textInfo(require(`./asset/images/summary/grill.png`), "เตา", pan_name)}
        {textInfo(require(`./asset/images/summary/package.png`), "แพ็คเกจ", price)}
        {textInfo(require(`./asset/images/summary/menu.png`), "เมนู", "คลิกเพื่อดูเมนู")}
        {textInfo(require(`./asset/images/summary/branch.png`), "สาขา", branch)}
        {textInfo(require(`./asset/images/summary/carpark.png`), "แนะนำจอดรถที่นี่", "คลิกไปยังแผนที่")}
        </div>
      </div>
    </div>
  );
}

export default Summary;
