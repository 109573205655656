import { useContext, useRef, useState } from 'react';
import { MainContext } from './main-context';
import Header from './components/Header';
import Steps from './components/Steps';
import Popup from 'reactjs-popup';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { pafekutoAPI } from './constant';
import { convertToDateDisplay } from './common/functions';

function Payment() {
  
  const [underStand, setUnderStand] = useState(true);

  const { primaryData, 
          screen, setScreen,
          shop, setShop, 
          branch, setBranch,
          menuType, setMenuType,
          paymentUrl, setPaymentUrl,
          price, setPrice,
          customerNameSurname, setCustomerNameSurname,
          customerTel, setCustomerTel,
          customerEmail, setCustomerEmail,
          customerRemark, setCustomerRemark,
          paymentSource, setPaymentSource,
          amount,
          slot,
          table,
          bookingDate,
          bookingTime
        } = useContext(MainContext);
        
  // PopUp Controller
  const refPopUpConfirm = useRef();
  const openPopup = () => refPopUpConfirm.current.open();
  const closePopup = () => refPopUpConfirm.current.close();

  const refPopUpTerm = useRef();
  const displayPopUpTerm = (dp) => {
    if(dp==='show'){
      refPopUpTerm.current.open();
    }else if(dp==='hide'){
      refPopUpTerm.current.close();
    }
  }

  
  
  // If data not ready then return empty (avoid error)
  if((!primaryData)||[shop,branch,menuType,bookingTime].includes(null)) return<></>;

  // Destruct important data
  const { shops, menuTypes } = primaryData;
  const { start='', end='' } = bookingTime;

  // Create key name for menuTypes
  const keyMenu = shop+"___"+branch;
  const { prices, label: labelMenu } = menuTypes[keyMenu][menuType];
  const labelShopBranchMenu = shops[shop].branch[branch].label+" "+labelMenu; 

  // Create Header info
  let shopName = null;
  let shopLogo = null;
  if((screen != "welcome")&&(screen != "shop")){
    shopName = labelShopBranchMenu;
    shopLogo = shops[shop].logo; 
  }
  const headerInfo = {
    title: shopName,
    logo: shopLogo
  };

  const reserveInfo = (icon, value) => {
    return <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
      <div><img src={icon} style={{ height: 32 }} /></div>
      <div style={{ marginLeft: 10 }}>{value}</div>
    </div>
  }
  
  /* let totalValue = 0;
  let reserveValue = 0;
  let labelPrice = null; 
  let unitPrice = null;
  if(prices[price]){   
    labelPrice = prices[price].label;
    unitPrice = prices[price].value;
    const currentPrice = parseFloat(unitPrice);
    totalValue = currentPrice*amount;
    reserveValue = parseInt(totalValue/2); 
  }
  */

  let totalValue = 0;
  let reserveValue = 0;
  let labelPrice = null; 
  let unitPrice = null;
  if(prices[price]){
    const { label, value, cc_value, fixed_deposit } = prices[price];
    labelPrice = label;
    if(paymentSource==="PPQR") unitPrice = parseFloat(value);
    else unitPrice = parseFloat(cc_value);
    reserveValue = parseInt(fixed_deposit) * amount;
    totalValue = unitPrice * amount;
  }

  const calculationInfo = (leftTxt, rightTxt) => {
    return <div style={{ 
      width: '90%',
      marginLeft: '5%',
      marginRight: '5%', 
      display: 'flex', 
      flexDirection: 'row', 
      justifyContent: 'space-between',
      marginTop: 7,
      fontSize: 14
    }} >
      <div>{leftTxt}</div>
      <div>{rightTxt}</div>
    </div>
  }


  const toPayment = async () => {
    const response = await axios.post(pafekutoAPI+"/gentoken", { info: {
      bookingDate,
      bookingTime: bookingTime.time_code,
      table,
      customerNameSurname,
      customerTel,
      customerEmail,
      customerRemark,
      totalValue,
      reserveValue,
      shop,
      branch,
      menuType,
      price,
      amount,
      paymentSource
    } });
    console.log(">>> To Payment");
    console.log("# response: ", response);
    if(response.status !== 200){
      alert("Payment API Error");
      return;
    }
    
    const {data} = response;
    if(!data){
      alert("Empty");
      return;
    }
    
    const { payload } = data;
    console.log("# payload response: ", payload);
    const decoded = jwt_decode(payload);
    console.log("# payload decoded: ", decoded);
    const { respCode, respDesc, paymentToken, webPaymentUrl } = decoded;
    console.log("#respCode: ", respCode);
    if(respCode !== "0000"){
      alert("ระบบขัดข้องไม่สามารถชำระเงินได้");
      console.log("#respDesc: ", respDesc);
      return;
    }
    
    setPaymentUrl(webPaymentUrl);
    setScreen("2c2p");

    /* const { data: dataInside } = data;
    const {payload} = dataInside;
    const decoded = jwt_decode(payload);
    console.log("# decoded: ", decoded);
    const { respCode, paymentToken, webPaymentUrl } = decoded;
    if(respCode !== "0000"){
      alert("Get Payment Page Unsuccessful");
      return;
    }

    setPaymentUrl(webPaymentUrl);
    setScreen("2c2p"); */
  }

  return (
    (screen == "payment") && 
    <>
      
      <Popup 
        ref={refPopUpTerm} 
        modal
        closeOnDocumentClick={false}
        contentStyle={{
          width: 380,
          height: 300,
          marginTop: 120,
          borderRadius: 10
        }}
      >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
        <div style={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems:'flex-start',
          height: 250,
          marginLeft: 10,
          marginRight: 10,
          marginTop: 10,
          fontSize: '12px',
          borderStyle: 'solid',
          borderColor: '#999999',
          backgroundColor: '#ffffcc',
          overflowY: 'scroll'
        }}>
          <div style={{ fontWeight: 'bold', marginTop: 10, color: 'blue' }} >เงื่อนไขการจอง ORANJII BUFFET</div>
          <div>1. การจองที่นั่งล่วงหน้าทุกแพ็จเกจ ต้องชำระมัดจำ 50% ของแพ็จเกจที่เลือก</div>
          <div>2. การจองนี้ ไม่สามารถยกเลิก หรือ ขอคืนเงิน ไม่ว่ากรณีใดๆ</div>
          <div>3. หากลูกค้า ชำระเงินมัดจำ 50% เรียบร้อยแล้ว จะถือว่าได้ยืนยันการจองโดยสมบูรณ์ “ทางร้านขอสงวนสิทธิ์ในการคืนเงินทุกกรณี”</div>
          <div>4. หากลูกค้าได้สำรองที่นั่ง และเลือกช่องทางการชำระเงินเรียบร้อยแล้ว จะไม่สามารถเปลี่ยนแปลงช่องทางการชำระเงินในภายหลังได้ ไม่ว่ากรณีใดๆ</div>

          <div style={{ fontWeight: 'bold', marginTop: 10, color: 'blue'  }} >เงื่อนไขการเปลี่ยนแปลงการจอง</div>
          <div>1. การจองนี้ ไม่สามารถลดจำนวน ผู้ใช้บริการได้ หากลูกค้าเข้าใช้บริการไม่ครบตามจำนวนที่จองไว้ ร้านขอสงวนสิทธิ์ ไม่คืนเงินทุกกรณี สำหรับลูกค้าที่ไม่มาใช้บริการ</div>
          <div>2. หากต้องการ เพิ่มจำนวน ผู้ใช้บริการ ต้องแจ้งล่วงหน้า อย่างน้อย 24 ชั่วโมง ก่อนถึงรอบเวลาการจอง โดยต้องแจ้งผ่านทางโทรศัพท์ 02 096 2888 หรือ อินบ๊อก  FB / Line : @oranjii  เท่านั้น</div>
          <div>3. กรณีต้องการเปลี่ยนแปลงวันและรอบเวลาเข้าใช้บริการ ลูกค้าจะ สามารถเปลี่ยนแปลงได้เพียง 1 ครั้ง โดยต้องแจ้ง ล่วงหน้าอย่างน้อย 48 ชั่วโมง  ก่อนถึงรอบเวลา โดยวันและรอบเวลาที่ต้องการเลื่อนต้อง ไม่เกิน 30 วัน นับจากวันและรอบเวลาเดิม ที่จองไว้ ทั้งนี้ลูกค้าจะต้องเลือกวันและรอบเวลาที่ว่างอยู่เท่านั้น หากรอบเวลาที่ต้องการไม่ว่าง จะไม่สามารถเปลี่ยนแปลงได้ ไม่ว่ากรณีใดๆ</div>
          <div>4. การเปลี่ยนแปลงแพ็จเกจ สามารถเปลี่ยนเป็นแพ็จเกจที่มี มูลค่าสูงกว่า แพ็จเกจเดิมที่ทำการจองมาแล้วเท่านั้น ไม่สามารถเปลี่ยนแปลงแพ็จเกจที่มูลค่าต่ำกว่าได้</div>
          
          <div style={{ fontWeight: 'bold', marginTop: 10, color: 'blue'  }} >เงื่อนไขการเข้าใช้บริการ</div>
          <div>1. วันเข้าใช้บริการ แนะนำให้มาถึงที่ร้านก่อนรอบเวลาอย่างน้อย  5-10 นาที จากนั้นแสดง QR การจองที่เคาท์เตอร์พนักงานเพื่อเข้ารับบริการกับพนักงาน หากมาช้ากว่าเวลาเปิดรอบ ร้านขอสงวนสิทธิ์ไม่ต่อเวลาการสั่งอาหารและรับประทานอาหารเพิ่มในทุกกรณี</div>
          <div>2. โอเรนจิ พร้อมส่งอาหารมื้อพิเศษให้คุณ โดยทุกเมนูในบุฟเฟต์ ลูกค้าสามารถรับประทานอาหารภายในร้านได้ไม่จำกัดตามรอบเวลาที่กำหนด (สั่งอาหาร 90 นาที ทานอาหาร 120 นาที)  หากรับประทานไม่หมด จะมีค่าปรับตามมูลค่าอาหาร และสามารถนำกลับบ้านได้</div>
          
          <div style={{ fontWeight: 'bold', marginTop: 10, color: 'blue'  }} >โปรดอ่านเงื่อนไขการจองโดยละเอียดก่อนการชำระเงิน</div>
          <div>การชำระเงินเป็นการยืนยันว่า ลูกค้าได้รับทราบและยอมรับเงื่อนไขต่างๆ เรียบร้อยแล้ว</div>
          <div>ไม่สามารถยกเลิกหรือขอคืนเงิน ไม่ว่ากรณีใดๆ</div>
        </div>
        <button style={{ cursor: 'pointer', marginTop: 5, fontWeight: 'bold' }} onClick={()=>{ displayPopUpTerm('hide') }}>Close</button>
      </div>
      </Popup>



      <Popup 
        ref={refPopUpConfirm} 
        modal
        contentStyle={{
          width: 300,
          height: 220,
          marginTop: 120,
          borderRadius: 10
        }}
      >
      <div style={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center',
        marginLeft: 10,
        marginTop: 10,
        marginTop: 20
      }}>
        <div style={{
        color: '#0000ff', 
        fontWeight: 'bold', 
        fontSize: 18}} >ไปยังหน้าจอชำระเงิน</div>
        <div style={{
        color: '#000000', 
        fontWeight: 'bold', 
        fontSize: 16,
        marginTop: 10
        }} >โปรดอย่าออกจากหน้าจอ <br/>จนการชำระเงินเสร็จสิ้น</div>
        <div style={{ marginTop: 30 }}>
          <button style={{
            padding: 10,
            borderStyle: 'none',
            borderRadius: 5,
            backgroundColor: '#000000',
            color: '#ffffff',
            fontSize: 18,
            fontWeight: 'bold',
            marginRight: 10,
            cursor: 'pointer'
          }} onClick={()=>{ toPayment() }} >ตกลง</button>
          <button style={{
            padding: 10,
            borderStyle: 'none',
            borderRadius: 5,
            backgroundColor: '#cccccc',
            color: '#000000',
            fontSize: 18,
            fontWeight: 'bold',
            cursor: 'pointer'
          }} onClick={()=>{ closePopup() }} >ออก</button>
        </div>
      </div>
      </Popup>
      <div className="Container">
        <Header info={headerInfo}/>
        {/*<Steps/>*/}
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '30px',
          width: '80%',
          marginLeft: '10%',
          marginRight: '10%',
        }}>
          <button style={{
            padding: 10,
            backgroundColor: '#cccccc',
            color: '#333333',
            fontWeight: 'bold',
            fontSize: 14,
            borderStyle: 'none',
            borderRadius: 5,
            cursor: 'pointer'
          }} onClick={()=>{ 
            setScreen("summary");
          }}>แก้ไขข้อมูล</button>
          <button style={{
            padding: 10,
            backgroundColor: '#ff0000',
            color: '#ffffff',
            fontWeight: 'bold',
            fontSize: 14,
            borderStyle: 'none',
            borderRadius: 5,
            cursor: 'pointer',
            width: '120px'
          }} onClick={()=>{ 
            if(!underStand){
              alert("โปรดยอมรับเงื่อนไขก่อนชำระเงิน");
              return;
            }

            openPopup();
          }}>ชำระเงิน</button>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '80%',
          marginLeft: '10%',
          marginRight: '10%',
          marginTop: 10,
          fontFamily: 'sans-serif',
          fontSize: 14
        }}>
          {reserveInfo(require(`./asset/images/summary/calendar.png`), convertToDateDisplay(bookingDate))}
          {reserveInfo(require(`./asset/images/summary/clock.png`), `${start} - ${end}`)}
        </div>
        <div style={{
          width: '80%',
          marginLeft: '10%',
          marginRight: '10%',
          fontFamily: 'sans-serif'
        }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: 20 }} >
            <div style={{ width: '100%', textAlign: 'left' }}  >วิธีการชำระ</div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} >
              <div className={paymentSource==="PPQR"?"Payment-Source-Active":"Payment-Source-DisActive"} onClick={()=>{ setPaymentSource("PPQR"); }} >
                <img src={require(`./asset/images/summary/qr-code.png`)} style={{ width: 16, marginRight: 5 }}/>
                QR Promtpay
              </div>
              <div className={paymentSource==="CC"?"Payment-Source-Active":"Payment-Source-DisActive"} onClick={()=>{ setPaymentSource("CC"); }} >
                <img src={require(`./asset/images/summary/credit-card.png`)} style={{ width: 16, marginRight: 5 }}/>
                Credit Card
              </div>
            </div>
          </div>
          <div style={{ 
            width: '100%',
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: '#ffffff',
            textAlign: 'left',
            marginTop: 10,
            fontSize: 14,
            marginBottom: 10
          }} >สรุปจำนวนเงินที่ต้องชำระ</div>
          {calculationInfo(`[${labelPrice}] ${unitPrice} x${amount}`, totalValue+" บาท")}
          {calculationInfo("ชำระเงินล่วงหน้า", reserveValue+" บาท")}
          <div style={{ 
            width: '100%',
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: '#ffffff',
            textAlign: 'left',
            marginTop: 15,
            fontSize: 18,
            marginBottom: 10
          }} ></div>
          <div style={{ fontSize: 14 }}><a style={{ color: 'white', fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }} onClick={()=>{ displayPopUpTerm('show') }} >ดูข้อกำหนดและเงื่อนไขการจองนี้</a></div>
          <div style={{ color: '#ff0000', fontSize: 14, marginTop: 10 }} >โปรดชำระเงินภายใน 5 นาที มิฉะนั้นออเดอร์นี้จะถูกยกเลิก</div>
          <div style={{ fontSize: 14, marginTop: 10  }} ><input type='checkbox' checked={underStand} onChange={()=>{ setUnderStand(!underStand) }} />ฉันได้อ่านและยอมรับ</div>
          <div style={{ fontSize: 14, marginTop: 10  }} >คำสั่งซื้อไม่สามารถคืนเงินและเปลี่ยนแปลงได้</div>
        </div>
      </div>
    </>
  );
}

export default Payment;
