import React, { useContext, useState, useEffect, useRef } from 'react';
import ReactLoading from 'react-loading'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from 'axios';
import { MainContext } from './main-context';
import Header from './components/Header';
import Steps from './components/Steps';
import { pafekutoAPI } from './constant';
import { fetchTableList } from './common/api';
import { contactStaffPopUp } from './constant';

function Tables() {
  const [renderTables, setRenderTables] = useState([]);
  const [switchTables, setSwitchTables] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [amountPage, setAmountPage] = useState(0);
  const [tables, setTables] = useState([]);
  const [isToday, setIsToday] = useState(false);
  const [maxPage, setMaxPage] = useState(0);
  const [room, setRoom] = useState("");
  const [labelButtonLeft, setLabelButtonLeft] = useState("");
  const [labelButtonRight, setLabelButtonRight] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const refPopUpTime = useRef();
  const openPopup = () => refPopUpTime.current.open();
  const closePopup = () => refPopUpTime.current.close();

  const [loadingTables, setLoadingTables] = useState(false);
  const [loadingTimes, setLoadingTimes] = useState(false);
  
  const calendarObj = require("./asset/images/calendar.png");
  const sqrPanObj = require("./asset/images/square-pan.png");
  const rndPanObj = require("./asset/images/round-pan.png");
  

  const nextObj = require("./asset/images/next-chevron.png");
  const backObj = require("./asset/images/back-chevron.png");

  const oranjiiSK1 = require("./asset/images/area-background/oranjii-sk-1.png");

  const isDateToday = (dateString) => {
    const today = new Date(); // Get the current date
    const dateToCompare = new Date(dateString); // Convert the date string to a Date object
  
    // Check if the year, month, and day of the two dates are the same
    return (
      today.getFullYear() === dateToCompare.getFullYear() &&
      today.getMonth() === dateToCompare.getMonth() &&
      today.getDate() === dateToCompare.getDate()
    );
  }

  const { 
    primaryData,
    screen,
    setScreen,
    shop,
    branch,
    menuType, 
    price,
    setPrice,
    amount,
    bookingDate,
    bookingTime,
    slot,
    table,
    tableDetail,
    setSlot,
    setTable,
    setTableName,
    setTableDetail,
    setBookingTime,
    mainPopUp,
    setMainPopUp
  } = useContext(MainContext);

  const renderPageList = (page_custom) => {
    console.log("# page_custom: ", page_custom);
    const list = [];
    try{ 
      for(let x=1; x<=maxPage; x++){
        const keyRef = room+'___'+x;
        list.push(<option 
          key={`page_${x}`} 
          value={x}
        >{page_custom[keyRef].label}</option>);
      }
    }catch(err){}
    return list;
  }

  const selectPage = (value) => {
   //alert(value);
    setActivePage(parseInt(value));
  }

  useEffect(() => {
    if(screen!=="tables") return;
  
    console.log("# shop: ", shop);
    console.log("# branch: ", branch);
    console.log("# menuType: ", menuType);
    console.log("# bookingDate: ", bookingDate); //2023-08-05
    console.log("# isDateToday: ", isDateToday(bookingDate));
    setIsToday(isDateToday(bookingDate));

    const getTableList = async () => {
      setLoadingTables(true);
      const data = await fetchTableList(shop, branch, menuType);
      setTables(data);
      let currentMaxPage = 0;
      let roomGet = "";
      data.map((row, i) => {
        const { room, page } = row;
        roomGet = room;
        const pageNumber = parseInt(page);
        if(pageNumber > currentMaxPage) currentMaxPage = pageNumber;
      });
      // alert(currentMaxPage);
      setMaxPage(currentMaxPage);
      // console.log("# roomGet: ", roomGet);
      setRoom(roomGet);
    }
    getTableList();
    
  }, [screen]); 

  useEffect(() => {
    updateRenderTables();
  }, [tables, activePage]);

  useEffect(()=>{
    getTablesReservedTimes();
  },[renderTables]);

  const updateRenderTables = () => {
    const aRender = [];
    let oSwitch = {};
    for(const row of tables){
      if(row.page === activePage){
        aRender.push(row);
      }
      oSwitch[row.code] = false;
    }
    setRenderTables(aRender);
    setSwitchTables({...switchTables, ...oSwitch});
  }

  const getTablesReservedTimes = () =>{
    const tables_check = [];
    for(const r of renderTables){
      tables_check.push(r.code);
    }
    
    if(tables_check.length<1) return;
    setLoadingTables(true);

    axios.post(`${pafekutoAPI}/get-tables-reserved-times`, {
      tables_check,
      day: getBookingWeekDay(bookingDate),
      bookingDate,
      menuType,
      price
    }).then((response)=>{
      console.log("#getTablesReservedTimes: ", response.data);
      const {data} = response;
      setLoadingTables(false);
      
      if(data.length<=0) return;
      let oUpdate = {};
      for(const r of data){
        oUpdate[r.table_code] = r.slot_now > r.reserved_now;
      }
      setSwitchTables({...switchTables, ...oUpdate});
    }).catch(function (error) {
      console.log("error: ", error);
      setLoadingTables(false);
    });
  }
  
  const getTimeSlots = (tblCode) => {
    //alert(menuType);
    axios.post(`${pafekutoAPI}/get-time-slots`, {
      //date: bookingDate,
      //date: "2023-04-04",
      table: tblCode,
      day: getBookingWeekDay(bookingDate),
      bookingDate,
      menuType
    })
    .then((response)=>{
      const { data } = response;
      console.log(">>> get-time-slots");
      console.log("# data: ", data);
      console.log(data);
      setTimeSlots(data);
      setLoadingTimes(false);
    }).catch(function (error) {
      console.log("error: ", error);
    });
  }

  const getBookingWeekDay = (date) => {
    const weekDayNum = new Date(date).getDay();
    const arrDayName = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    return arrDayName[weekDayNum];
  }

  //console.log("# primaryData: ", primaryData);
  if((!primaryData)||[shop,branch,menuType].includes(null)) return<></>;
  
  // Destruct important data
  const { main, shops, menuTypes } = primaryData;
  const { page_custom } = main;
  console.log("# page_custom: ", page_custom); 
  console.log("page_custom[room]: ", page_custom[room+'___'+(activePage+1)]);

  // Create key name for menuTypes
  const keyMenu = shop+"___"+branch;
  const { prices, label: labelMenu } = menuTypes[keyMenu][menuType];
  const labelShopBranchMenu = shops[shop].branch[branch].label+" "+labelMenu;

  // Create Header info
  let shopName = null;
  let shopLogo = null;
  if((screen != "welcome")&&(screen != "shop")){
    shopName = labelShopBranchMenu;
    shopLogo = shops[shop].logo; 
  }
  const headerInfo = {
    title: shopName,
    logo: shopLogo
  };
 
  const pageButtonStyle = {
    backgroundColor: '#ffffff',
    color: '#0000ff',
    cursor: 'pointer',
    borderStyle: 'none',
    borderRadis: 5,
    padding: '5px 15px 5px 15px'
  }

  const getCurrentTime = () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    return `${hours}:${minutes}`
  }

  const showALaCarteMinimum = () => {
    let priceLabel = "";
    try{
      const keyGetPrice = `${shop}___${branch}`;
      const {alacarte_min_price} = tableDetail;
      priceLabel = primaryData.menuTypes[keyGetPrice][menuType].prices[alacarte_min_price].label;
    }catch(err){}
    
    return priceLabel;
  }
  if((screen === "tables") && (loadingTables)) return (
    <div className="Container">
      <Header info={headerInfo}/>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '10px'}}>
        <div className="lds-facebook"><div></div><div></div><div></div></div>
      </div>
    </div>
  );

  if((screen === "tables") && (tables.length > 0)) return (
    <>
    <Popup 
      ref={refPopUpTime} 
      modal
      contentStyle={{
        width: 270,
        height: 360,
        borderRadius: 10
      }}
    >
    <div style={{ 
      marginLeft: 20,
      marginTop: 10,
      marginBottom: 10,
      color: '#0000ff', 
      fontWeight: 'bold', 
      fontSize: 28 
    }}>{table}</div>
    {(menuType==="alacarte")&&<div style={{marginLeft: 20, marginTop: 10, marginBottom: 10, color: '#ff0000', fontSize: 16, fontWeight: 'bold' }}>{showALaCarteMinimum()}</div>}
    {loadingTimes && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '10px'}}>
      <div className="lds-facebook"><div></div><div></div><div></div></div>
    </div>}
    {timeSlots.map((row, i) => {
        console.log("# row time: ", row);
        const { time_code, start, end, status, tx_code, prices } = row;
        const cleaned_prices = prices.replace(/ /g, "");
        const allowed_prices = cleaned_prices.split(",");
        const found_price = allowed_prices.includes(price);
        const currentTimeInt = parseInt(getCurrentTime().replace(':',''));
        const startTimeInt = parseInt(start.replace(':',''));
        const past = (currentTimeInt>startTimeInt) && isToday;
        
        let disabled = true;
        if(menuType !== 'alacarte') disabled = !found_price || tx_code || past;
        else disabled = tx_code || past;
        
        return <div
          style={{ 
            marginLeft: 20,
            marginRight: 20,
            marginBottom: 10,
            cursor: 'pointer',
            backgroundColor: (disabled?'#cccccc':'#ffff66'),
            textAlign: 'center',
            padding: 10,
            fontSize: 18,
            fontWeight: (disabled?'normal':'bold'),
            color: (disabled?'#666666':'#000000')
          }}
          onClick={()=>{ 
            if(tx_code){
              alert("มีคนจองแล้ว");
              return;
            }
            if(!found_price){
              alert("ราคาที่ท่านเลือกไม่สามารถจองได้");
              return;
            }
            if(past){
              alert("ไม่สามารถเลือกเวลาที่เริ่มไปแล้วได้");
              return
            }
            setBookingTime({
              time_code,
              start,
              end
            });
            closePopup();
            setScreen("summary");
          }}
        >{`${start} - ${end}`}</div>
    })}
    </Popup>
    <div className="Container">
      <Header info={headerInfo}/>
      {/*<Steps/>*/}
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0, marginLeft: 30, marginRight: 30, justifyContent: 'space-between', fontSize: 18, fontFamily: 'sans-serif' }} >
        <img 
            src={calendarObj} 
            style={{ height: 32 }} 
            onClick={()=>{ setScreen("date-booking") }}
        />
        <select 
          value={activePage}              
          style={{ right: 60, height: '30px' }}  
          onChange={(event)=>{ selectPage(event.target.value); }}
        >
          {renderPageList(page_custom)}
        </select>
        <div style={{ display: 'flex', flexDirection: 'column', borderStyle: 'solid', padding: '5px 5px 5px 5px', borderRadius: '7px' }} >
          <div style={{ display: 'flex', flexDirection: 'row' }} >
            <div><img src={rndPanObj} style={{ width: 24, height: 24 }} /></div>
            <div style={{ marginLeft: 10, color: 'yellow', fontSize: 12 }} >เตาปิ้งย่างชาบูทรงกลม</div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }} >
            <div><img src={sqrPanObj} style={{ width: 24, height: 24 }} /></div>
            <div style={{ marginLeft: 10, color: 'yellow', fontSize: 12 }} >เตาปิ้งย่างสี่เหลี่ยม</div>
          </div>
        </div>
      </div>
      <div style={{ 
        position: 'relative', 
        width: '100%', 
        marginLeft: 0, 
        marginRight: 0, 
        marginTop: 50
      }}>
        {(activePage === 1 && room === 'oranjii-sk') && <img src={oranjiiSK1} style={{
          width: '350px',
          height: '400px',
          position: 'absolute',
          top: '0px',
          left: '0px'
        }} />}
        {
          renderTables.map((row, i) => {
            const { room, page, pos, code, name, table_type, left, top, min, max, rotation, offer_types_allowed, contact_staff  } = row;
            console.log("# rotation: ", rotation);
            const txtRef = `${code}_${table_type}`;
            console.log(txtRef)
            const iconTbl = require(`./asset/images/table-types/${table_type}.png`);
            const degreeRotate = parseFloat(rotation);

            if(page > amountPage) setAmountPage(page);

            if(page === activePage){
              let amountTable = false;
              if((amount>=min)&&(amount<=max)) amountTable = true;

              let disAllowedOfferType = true;
              if(offer_types_allowed.search(menuType) > -1) disAllowedOfferType = false;

              const logicEnable = () =>{
                //return amountTable && !disAllowedOfferType;
                return amountTable && switchTables[code];
              }
              return <div key={txtRef} style={{
                  position: 'absolute',
                  top: top,
                  left: left
                }} 
              >
              <img src={iconTbl}  
                style={{ 
                  height: 52, 
                  transform: (degreeRotate>0?"rotate("+degreeRotate+"deg)":'none'),
                  opacity: (logicEnable()?1:0.2)
                }} 
                onClick={()=>{ 
                  if(contact_staff){
                    const { vip } = contactStaffPopUp;
                    const { title, detail, width, height } = vip;
                    setMainPopUp({
                      ...mainPopUp, 
                      display: true,
                      title,
                      detail,
                      width,
                      height
                    });
                    return;
                  }

                  if(disAllowedOfferType){
                    alert("ประเภทเมนูที่เลือกไม่สามารถจองโต๊ะนี้ได้");
                    return;
                  }

                  if(!amountTable){
                    alert("จำนวนคนไม่สามารถจองโต๊ะนี้ได้");
                    return;
                  }

                  if(!switchTables[code]){
                    alert("เซ็ทเมนูที่ท่านเลือกไม่สามารถจองได้");
                    return;
                  }

                  setTimeSlots([]);
                  setTable(code);  
                  setTableDetail({...tableDetail, ...row});
                  setTableName(name);
                  setLoadingTimes(true);
                  getTimeSlots(code);
                  if(menuType==='alacarte') setPrice(row.alacarte_min_price);
                  openPopup();
                }}
              />
              <div style={{ 
                position: 'absolute', 
                top: 14, 
                right: 'calc(10%)', 
                color: 'red',
                fontFamily: 'sans-serif',
                fontSize: '14px',
                fontWeight: 'bold',
                textShadow: "0px 0px 3px #000000, 0 0 10px #ffffff"
              }} >{name}</div>
              </div>;
            }
            return <></>;
          })
        }
      </div>
    </div>
    </>
  );
}

export default Tables;
