import { useContext } from 'react';
import { MainContext } from './main-context';
import Header from './components/Header';
import magnifier from './asset/images/magnifier.png'
import wow from './asset/images/wow.png'
function Welcome() {
  const { screen, setScreen } = useContext(MainContext);
  const handleClick = () => {
    setScreen("shop");
  }
  
  const headerInfo = {
    title: "Pafekuto Group",
    logo: "pafekuto.jpg"
  };

  return (
    (screen == "welcome") && 
    <div className="Container">
      <Header info={headerInfo}/>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}
      ><div><img 
        src={wow}
        style={{
          width: 96,
          marginLeft: 40
        }} 
        onClick={handleClick}
      /></div><div><img 
        src={magnifier}
        style={{
          width: 48,
          cursor: 'pointer',
          marginLeft: 10
        }} 
        onClick={handleClick}
      /></div></div>
    </div>
  );
}

export default Welcome;
